
























































































































.handler_activated {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  padding: 10px;
  .handler_teach {
    @media (min-width: 767px) {
      width: 49%;
    }
    @media (min-width: 320px) and(max-width: 767px) {
      width: 100%;
    }
  }
}
